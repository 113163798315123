<template>
  <v-snackbar
    v-model="visible"
    multi-line
    :color="color"
    :timeout="timeout"
    right
    shaped
    top
  >
    {{ message }}

    <template
      v-slot:action="{ attrs }"
    >
      <v-btn
        v-if="link"
        :href="link"
        icon
        color="white"
        target="_blank"
      >
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn
        v-else
        color="primary"
        text
        v-bind="attrs"
        @click="visible = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import EventBus from '@/plugins/event-bus'

export default {
  name: 'Snackbar',
  data() {
    return {
      link: null,
      timeout:5000,
      visible: false,
      message: '',
      color: 'green',
      y: 'top',
      x : 'right',
    }
  },
  created () {
    EventBus.$on('SNACKBAR',  ({ color, message, link = null }) => {
      this.color = color
      this.message = message
      this.link = link
      this.show()
    })
  },
  methods: {
    show() {
      this.visible = true
    },
  },

}
</script>
